import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { useHistory } from "react-router-dom";
import { apilink } from "../../data/fdata";
import axios from "axios";
import Cookies from "js-cookie";
import SideBar from "../../component/sidebar/SideBar";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

const ProductSellDetails = () => {
  const token = Cookies.get("_indiayour_seller_access_token");

  const his = useHistory();
  const [pageloading, setPageLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [filter, setFilter] = useState("TL");


  useEffect(async () => {
    if (token) {
      setPageLoading(true);
      const res = await axios.get(`${apilink}/api/seller/authVerify`, {
        headers: {
          Authorization: token,
        },
      });
      if (!res.data.success) {
        Cookies.remove("_indiayour_seller_access_token");
        localStorage.removeItem("_indiayour_seller_access_login");
        console.clear();
        window.location.href = "/";
      } else {
        if (!res.data.userInfo.isApproved) {
          his.push("/business/add");
        }
      }
      setTimeout(() => {
        setPageLoading(false);
      }, 2000);
    } else {
      his.push("/");
    }
  }, []);

  const getOrderProductCountforseller = async () => {
    const res = await axios.get(
      `${apilink}/api/order/getOrderProductCountforseller`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    // console.log(res.data);
    if (res.data.success) {
      setProductList(res.data.result.sort((a,b)=>b.totalQuantity - a.totalQuantity));
    }
  };

  useEffect(() => {
    getOrderProductCountforseller();
  }, []);

  const handelItem=(e)=>{
    setFilter(e.target.value)
    if(e.target.value=="TL")
    {
        setProductList(productList.sort((a,b)=>b.totalQuantity - a.totalQuantity));
    }else{
        setProductList(productList.sort((a,b)=>a.totalQuantity - b.totalQuantity));
    }
  }
  return (
    <>
      <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
            <div className="container-fluid">
              <h3 className="text-custom">Product Sell Details</h3>
              <div className="dd_flex">
          <h5></h5>
          <div>
            <div class="form-group">
              <select class="form-control filterform"  value={filter} onChange={handelItem} >
                
               
                <option value="TL">Qty:T-L</option>
                <option value="LT">Qty:L-T</option>
               
              </select>
            </div>
          </div>
        </div>

             {
              productList.length > 0 ?(
                <>
                 <div className="productflex mt-3">
               {
                productList?.map((val,ind)=>{
                    return(
                        <>
                         <div className="product_item">
                  <div className="diplay_flex">
                    {
                        val.productImage?.length > 0 && <img src={val.productImage[0]} alt="" />
                    }
                   

                    <div className="ml-2">
                      <p
                        className="m-0 sm_p fn_18 cur mb-2"
                        onClick={() => his.push(`/edit/${val._id}`)}
                      >
                        {val?.productName}
                      </p>

                      <p className="fn_14 m-0">
                        <b>Total Request:</b> &nbsp; {val?.totalOccurrences}
                      </p>
                      <p className="fn_14 m-0">
                        <b>Total Qty:</b> &nbsp; {val?.totalQuantity}
                      </p>
                    </div>
                  </div>
                </div>
                        </>
                    )
                })
               }
                
              </div>
                
                </>
              ):(
                <>
                <div className="text-center">
                  <div className="p-3">
                    <p>No Item Found</p>
                  </div>
                </div>
                </>
              )
             }
            </div>
          </div>
        </div>
      </div>

      {pageloading && (
        <>
          <div className="pageloading">
            <div class="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ProductSellDetails;
