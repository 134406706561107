import React from 'react'
import SideBar from '../component/sidebar/SideBar'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { path } from '../data/fdata';
const Wrong = () => {
  const his = useHistory();

  return (
    <>
    
    <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
            <div className="container-fluid">
             <div className="row">
                <div className="col-md-6 mx-auto mt-5 pt-5">
                    <img src={`${path}/image/error.png`} alt="" className='wrongimg' />
                   
                    <div className="text-center mt-3">
                    <h4>Something Wrong</h4>
                        <button className='btn btn-primary' onClick={()=>his.push("/home")}>Go Back</button>
                    </div>

                </div>
             </div>

              
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Wrong