import React, { useEffect, useState } from 'react';

import { Switch, Route, useHistory, useLocation } from 'react-router-dom';

import Error from './pages/Error';
import Cookies from 'js-cookie';
import './App.css';
// import Navbar from './component/navbar/NavBar';
import NavBar from './component/navbar/NavBar';
import LoginU from './pages/auth/LoginU';
import Register from './pages/auth/Register';

import Footer from './component/footer/Footer';

import Home from './pages/home/Home';
import AccountDet from './pages/dashboard/AccountDet';
import AddItem from './pages/dashboard/AddItem';
import MyList from './pages/dashboard/MyList';
import Setting from './pages/dashboard/Setting';
import Business from './pages/dashboard/Business';
import OrderRequest from './pages/dashboard/OrderRequest';
import EditItem from './pages/dashboard/EditItem';
import ActiveAccount from './pages/auth/ActiveAccount';
import { ConText } from './context/DataContext';
import EditBusiness from './pages/dashboard/EditBusiness';
import ProductSellDetails from './pages/dashboard/ProductSellDetails';
import Wrong from './pages/Wrong';
import ForgetPassword from './pages/auth/ForgetPassword';
import ResetPassword from './pages/auth/ResetPassword';
import LoginPage from './pages/auth/LoginPage';
import RegisterPage from './pages/auth/RegisterPage';

const App = () => {
  const his = useHistory();
  const loc = useLocation();
  const [loginstaus, setLoginStatus] = useState(false);
  // console.log(loc);
  
  const [userDetails, setUserDetails] = useState([]);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [loc]);

  return (
    <>
    <ConText>
      <NavBar />
      <Switch>
        {/* // without login */}
        <Route exact path="/register" component={RegisterPage} />
        <Route exact path="/" component={LoginPage} />
        <Route exact path="/forget-password" component={ForgetPassword} />
        <Route exact path="/reset-password/:accesstoken" component={ResetPassword} />

        

        <Route exact path="/home" component={Home} />
        <Route exact path="/additem" component={AddItem} />
        <Route exact path="/edit/:proid" component={EditItem} />

        <Route exact path="/mylist" component={MyList} />
        <Route exact path="/setting" component={Setting} />
        <Route exact path="/account" component={AccountDet} />
        <Route exact path="/business/add" component={Business} />
        <Route exact path="/business/edit" component={EditBusiness} />
        <Route exact path="/product-sell-details" component={ProductSellDetails} />


        <Route exact path="/order-requests" component={OrderRequest} />
        <Route exact path="/verify-account/:activetoken" component={ActiveAccount} />
        <Route exact path="/something-wrong" component={Wrong} />

        <Route component={Error} />2
      </Switch>
      <Footer />
      </ConText>
    </>
  );
};

export default App;
