import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { apilink } from "../../data/fdata";
import axios from "axios";
import Cookies from "js-cookie";
import SideBar from "../../component/sidebar/SideBar";
import { CategoryList, stateList, teamList } from "../../data/stateList";
import { useAlert } from "react-alert";
import { CircularProgress } from "@mui/material";

const EditBusiness = () => {
  const alert = useAlert();
  const token = Cookies.get("_indiayour_seller_access_token");
  const [address, setAddress] = useState("");
  const [pin, setPin] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [category, setCategory] = useState("");
  const [btype, setBType] = useState("");
  const [description, setDescription] = useState("");
  const [teamSize, setTeamSize] = useState("");
  const [gstin, setGstin] = useState("");
  const [pan, setPan] = useState("");
  const [gstfile, setGstfile] = useState([]);
  const [panfile, setPanfile] = useState([]);

  const [gstLink, setGstLink] = useState("");
  const [panLink, setPanLink] = useState("");
  const [showValue, setShowValue] = useState("");

  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  const [msg, setMsg] = useState("");

  const his = useHistory();
  // const { type } = useParams();
  
  const [pageloading, setPageLoading] = useState(false);

  const getVerify = async () => {
    setPageLoading(true);
    const res = await axios.get(`${apilink}/api/seller/authVerify`, {
      headers: {
        Authorization: token,
      },
    });
    if (!res.data.success) {
      Cookies.remove("_indiayour_seller_access_token");
      localStorage.removeItem("_indiayour_seller_access_login");
      console.clear();
      window.location.href = "/";
    } else {
    //   // add data
    //   if (res.data.userInfo.isApproved) {
    //     setAccountApproved(false);
    //   }


      if(!res.data.userInfo.isApproved) {
        his.push("/business/add")
    }
      setAddress(res.data.userInfo?.businessDetails?.address);
      setCity(res.data.userInfo?.businessDetails?.city);
      setState(res.data.userInfo?.businessDetails?.state);
      setPin(res.data.userInfo?.businessDetails?.pincode);
      setBType(res.data.userInfo?.businessDetails?.businessType);
      setCategory(res.data.userInfo?.businessDetails?.category);
      setDescription(res.data.userInfo?.businessDetails?.sellerDet);
      setTeamSize(res.data.userInfo?.businessDetails?.teamsize);
      setGstin(res.data.userInfo?.businessDetails?.gstinNo);
      setPan(res.data.userInfo?.businessDetails?.panNo);
      setGstLink(res.data.userInfo?.businessDetails?.gstinFile);
      setPanLink(res.data.userInfo?.businessDetails?.panFile);
    }
    setTimeout(() => {
      setPageLoading(false);
    }, 2000);
  };

  useEffect(async () => {
    if (token) {
      getVerify();
    } else {
      his.push("/");
    }
  }, []);

  const onSub = async (e) => {
    e.preventDefault();
setLoading(true)
    const data = {
        address,
        city,
        state,
        pincode: pin,
        businessType: btype,
        category,
        sellerDet: description,
        teamsize: teamSize,
        gstinNo: gstin,
        gstinFile: gstLink,
        panNo: pan,
        panFile: panLink,
      };

      const result = await axios.post(
        `${apilink}/api/seller/updateBusinessDetails`,
        data,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      if(result.data.success) {
        alert.success("Business Details Update Successfully")
        setTimeout(() => {
            setLoading(false)
            his.push("/account")
          }, 5000);
      }else{
        setTimeout(() => {
            setLoading(false)
                      }, 5000);
        alert.error(result.data.msg)
      }

    //   console.log(result.data);




  };

 

  return (
    <>
      <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
            <div className="container-fluid">
              
              <h3 className="text_Deco">
                Edit Business Details
              </h3>
              <div className="row mt-5 p-2">
                <div className="col-12">
                  {status ? (
                    <>
                      <div class="alert alert-warning alert-dismissible">
                        <button
                          type="button"
                          class="close"
                          data-dismiss="alert"
                          onClick={() => setStatus(false)}
                        >
                          &times;
                        </button>
                        {msg}
                      </div>
                    </>
                  ) : null}
                  <form onSubmit={onSub}>
                    <div class="form-row">
                      <div className="form-group col-lg-6 col-md-6">
                        <input
                          type="text"
                          placeholder="Enter Address"
                          className="form-control form_h"
                          name="address"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          required
                        />
                      </div>

                      <div className="form-group col-lg-6 col-md-6">
                        <input
                          type="text"
                          placeholder="Enter City"
                          className="form-control form_h"
                          name="city"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-lg-6 col-md-6">
                        <select
                          class="form-control form_h"
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                          required
                        >
                          <option selected hidden value="">
                            --Choose State--
                          </option>
                          {stateList?.map((val, ind) => {
                            return (
                              <>
                                <option key={ind} value={val.state}>
                                  {val.state}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </div>
                      <div class="form-group col-lg-6 col-md-6">
                        <input
                          type="number"
                          class="form-control form_h"
                          placeholder="Enter Pincode"
                          name="pin"
                          value={pin}
                          onChange={(e) => setPin(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div class="form-group col-lg-6 col-md-6">
                        <input
                          type="text"
                          class="form-control form_h"
                          placeholder="Enter Business Type"
                          name="btype"
                          value={btype}
                          onChange={(e) => setBType(e.target.value)}
                          required
                        />
                      </div>
                      <div className="form-group col-lg-6 col-md-6">
                        <select
                          class="form-control form_h"
                          value={category}
                          onChange={(e) => setCategory(e.target.value)}
                          required
                        >
                          <option selected hidden value="">
                            --Choose Category--
                          </option>
                          {CategoryList?.map((val, ind) => {
                            return (
                              <>
                                <option key={ind} value={val.title}>
                                  {val.title}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="form-row">
                      <div class="form-group col-lg-6 col-md-6">
                        <div class="form-group">
                          <textarea
                            class="form-control"
                            rows="3"
                            placeholder="Enter Seller Description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            required
                          ></textarea>
                        </div>
                      </div>
                      <div className="form-group col-lg-6 col-md-6">
                        <select
                          class="form-control form_h"
                          value={teamSize}
                          onChange={(e) => setTeamSize(e.target.value)}
                          required
                        >
                          <option selected hidden value="">
                            --Choose TeamSize-
                          </option>
                          {teamList?.map((val, ind) => {
                            return (
                              <>
                                <option key={ind} value={val.title}>
                                  {val.title}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    {!gstLink && <small>Enter GSTIN & Upload File</small>}
                    <div class="form-row">
                      <div className="form-group col-lg-6 col-md-6">
                        {gstLink && <small>Enter GSTIN & Upload File</small>}
                        <input
                          type="text"
                          placeholder="Enter GSTIN"
                          className="form-control form_h"
                          name="gstin"
                          value={gstin}
                          onChange={(e) => setGstin(e.target.value)}
                          
                          disabled={gstLink.length>0 ? true : false}
                        />
                      </div>

                      <div className="form-group col-lg-6 col-md-6">
                        {gstLink && (
                          <small
                            onClick={() => setShowValue(gstLink)}
                            className="cur text-custom"
                            data-toggle="modal"
                            data-target="#myModal"
                          >
                            View <i className="fa fa-external-link"></i>{" "}
                          </small>
                        )}
                        <input
                          type="file"
                          className="form-control form_h"
                          name="gstinfile"
                          accept=".png, .jpeg , .jpg "
                          
                         
                          
                          disabled={gstLink.length>0 ? true : false}
                        />
                      </div>
                    </div>
                    {!panLink && <small>Enter PAN No. & Upload File</small>}
                    <div class="form-row">
                      <div className="form-group col-lg-6 col-md-6">
                        {panLink && <small>Enter PAN No. & Upload File</small>}
                        <input
                          type="text"
                          placeholder="Enter PAN No."
                          className="form-control form_h"
                          name="pan"
                          value={pan}
                          onChange={(e) => setPan(e.target.value)}
                          
                          disabled={panLink.length>0 ? true : false}
                        />
                      </div>

                      <div className="form-group col-lg-6 col-md-6">
                        {panLink && (
                          <small
                            onClick={() => setShowValue(panLink)}
                            className="cur text-custom"
                            data-toggle="modal"
                            data-target="#myModal"
                          >
                            View <i className="fa fa-external-link"></i>{" "}
                          </small>
                        )}

                        <input
                          type="file"
                          className="form-control form_h"
                          name="panfile"
                          accept=".png, .jpeg , .jpg "
                          
                          disabled={panLink.length>0 ? true : false}
                        />
                      </div>
                    </div>

                    <div className="text-right">
                      <button
                        type="submit"
                        className={
                          loading
                            ? "dis btn btn-primary pl-5 pr-5"
                            : "btn btn-primary pl-5 pr-5"
                        }
                        disabled={loading}
                      >
                        Update Details
                      </button>
                    </div>

                    {loading && (
                      <div className="text-center p-2">
                        <CircularProgress color="error" size={35} />
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="myModal">
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">View Document</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>

            <div class="modal-body">
              {/* {
              showValue ?  <img src={showValue} alt="" className='doc_img' /> : "loading..."
            } */}

              <img src={showValue} alt="" className="doc_img" />
            </div>
          </div>
        </div>
      </div>

      {pageloading && (
        <>
          <div className="pageloading">
            <div class="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EditBusiness;
