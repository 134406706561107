import React from 'react'
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import { generateRandomColor } from '../../data/fdata';

const Piechart = ({data}) => {
   

  return (
    <>
     <ResponsiveContainer width={"100%"} height={322}>
        <PieChart >
          <Pie
          data={data.data}
            dataKey={data.type}           
             nameKey="name"
            
            cx="50%"
            cy="50%"
            outerRadius={100}
            fill="#8884d8"
            label
          />


         
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
     
    </>
  )
}

export default Piechart