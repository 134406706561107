import Cookies from 'js-cookie';
import React, { createContext, useState, useEffect } from 'react';
import { apilink } from '../data/fdata';
import axios from 'axios';

export const DataContext = createContext();



export const ConText = (props) => {
  const [account, setAccount] = useState([]);
  const [accountLogin, setAccountLogin] = useState(true);
  const [accountApproved, setAccountApproved] = useState(true);
  const [contactData,setContactData]=useState({})
  
  const [categoryList,setCategoryList]=useState([])


  const getallCategory = async()=>{
    const res=await axios.get(`${apilink}/api/category/getallCategory`)
    if(res.data.success)
    {
      setCategoryList(res.data.result)
    }
  }
  const getAllSocial=async()=>{
    const res=await axios.get(`${apilink}/api/contactlink/getContactLinks`)
    if(res.data.success)
    {
      setContactData(res.data.result)
      
    }
  }

  useEffect(()=>{
    getallCategory()
    getAllSocial()
  },[])

  function removeSpecialCharacters(text) {
    // Define a regular expression pattern to match special characters
    const regexPattern = /[&\/\\#,+()$~%.'":*?<>{}]/g;
  
    // Use the replace method to remove the special characters
    let cleanedText = text?.replace(regexPattern, '');
    cleanedText = cleanedText?.replace(/\s+/g, ' ');
  // console.log(cleanedText)
    return cleanedText;
  }



 
 
 
  return (
    <>
      <DataContext.Provider
        value={{contactData , categoryList ,removeSpecialCharacters }}
      >
        {props.children}
      </DataContext.Provider>
    </>
  );
};
