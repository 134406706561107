import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './NavBar.css';
import Cookies from 'js-cookie';
import { styled, alpha } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { green, red } from '@mui/material/colors';
import PersonIcon from '@mui/icons-material/Person';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PolicyIcon from '@mui/icons-material/Policy';
import { apilink, headerNav, path } from '../../data/fdata';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import ListAltIcon from '@mui/icons-material/ListAlt';
import axios from 'axios';

const NavBar = ({ userDetails, loginstaus }) => {
  const login_token = Cookies.get('_indiayour_seller_access_token');
  
  const [searchcheck, setSearchCheck] = useState(false);
  const [search, setSearch] = useState('');
  const [logoutstatus, setLogoutstatus] = useState(false);
  const [status, setStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openn = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSub = async (e) => {
    e.preventDefault();
    setSearchCheck(false);
  };
 
  useEffect(async() => {
    // console.log(login_token)
    if(login_token) {
      
      const res=await axios.get(`${apilink}/api/seller/authVerify`,{
          headers: {
            Authorization: login_token,
          },
        })
        // console.log(res.data)
        if(!res.data.success)
        {
          Cookies.remove("_indiayour_seller_access_token");
      localStorage.removeItem("_indiayour_seller_access_login");
      console.clear();
      window.location.href = "/";
        }else{
         
          setStatus(true)


        }
       
  }else{
    setStatus(false)
  }
  }, []);

  const logout = () => {
    Cookies.remove('_indiayour_seller_access_token');
    localStorage.removeItem('_indiayour_seller_access_login');
    console.clear();
    window.location.href = '/';
  };
  return (
    <>
      {
        status ?(
          <>
          <div className="whole_nav">
        {searchcheck ? (
          <>
            <form action="" onSubmit={onSub}>
              <div className="searchdiv">
                <div class="input-group ">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Type to Search..."
                    value={search}
                    required
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <div class="input-group-append">
                    <button type="submit" class="input-group-text btn">
                      <i class="fa fa-search" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
                <i
                  class="fa fa-times cur"
                  style={{ fontSize: '18px' }}
                  aria-hidden="true"
                  onClick={() => {
                    setSearchCheck(false);
                    setSearch('');
                  }}
                ></i>
              </div>
            </form>
          </>
        ) : (
          <>
            <nav className="navbar navbar-expand-lg navbar-light bglight">
              <div className="dflex">
                <MenuIcon
                  className="cur mr-2 resp_dis"
                  onClick={() => setOpen(true)}
                />
                <NavLink
                  to="/home"
                  className="navbar-brand text-custom font-weight-bold dflex"
                >
                  <img
                    src={`${path}/image/Indiayour.png`}
                    alt=""
                    className="logoimg"
                  />
                </NavLink>
              </div>

              <div>
                <div className="dflex">
                  {/* <SearchIcon
                        className="cur"
                        onClick={() => setSearchCheck(true)}
                      /> */}
                  <Tooltip title="Account settings">
                    <IconButton
                      onClick={handleClick}
                      size="small"
                      sx={{ ml: 2 }}
                      aria-controls={openn ? 'account-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openn ? 'true' : undefined}
                    >
                      <Avatar
                        sx={{ bgcolor: red[900], width: 32, height: 32 }}
                      ></Avatar>
                    </IconButton>
                  </Tooltip>
                </div>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={openn}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  className="right_nav_link"
                >
                  <MenuItem>
                    <NavLink to="/account">
                      <ListItemIcon>
                        <PersonIcon fontSize="small" />
                      </ListItemIcon>
                      Account details
                    </NavLink>
                  </MenuItem>
                  <MenuItem>
                    <NavLink to="/setting">
                      <ListItemIcon>
                        <SettingsSuggestIcon fontSize="small" />
                      </ListItemIcon>
                      Setting
                    </NavLink>
                  </MenuItem>
                  <MenuItem>
                    <NavLink to="/mylist">
                      <ListItemIcon>
                        <ListAltIcon fontSize="small" />
                      </ListItemIcon>
                      My Listing
                    </NavLink>
                  </MenuItem>
                  <Divider />

                  <MenuItem onClick={() => setLogoutstatus(true)}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </div>
            </nav>
            <Drawer open={open} onClose={() => setOpen(false)}>
              <Toolbar className="nav-wid">
                <NavLink to="/home" onClick={() => setOpen(false)}>
                  <h3>
                    Indiayour<span>.</span>
                  </h3>
                </NavLink>
              </Toolbar>

              {/* <Divider /> */}

              <List disablePadding className="listsize">
                {headerNav.map((v) => {
                  return (
                    <>
                      <ListItem
                        button
                        component={Link}
                        to={`${v.path}`}
                        onClick={() => setOpen(false)}
                      >
                        <ListItemText primary={`${v.display}`} />
                      </ListItem>
                    </>
                  );
                })}
              </List>
            </Drawer>
            {logoutstatus && (
              <div className="modbox">
                <div className="smbox">
                  <div
                    className="btn_close"
                    onClick={() => setLogoutstatus(false)}
                  >
                    X
                  </div>
                  <p>Are you sure about Logout? </p>
                  <button className="btn btn-primary" onClick={() => logout()}>
                    Logout
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </div>

          </>
        ):(
          <>
          <div className="whole_nav">
          <nav className="navbar navbar-expand-lg navbar-light bglight">
         
               
                <NavLink
                  to="/"
                  className="navbar-brand text-custom font-weight-bold dflex"
                >
                  <img
                    src={`${path}/image/Indiayour.png`}
                    alt=""
                    className="logoimg"
                  />
                </NavLink>
              
              <div className="dflex">
               
               <NavLink
                 to="/"
                 className="navbar-brand text-custom  "
               >
                 Login
               </NavLink>
               <NavLink
                 to="/register"
                 className="btn btn-primary ml-2"
               >
                 Register
               </NavLink>
             </div>
            </nav>

          </div>

          </>
        )
      }
    </>
  );
};

export default NavBar;
