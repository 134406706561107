import React, { useEffect, useState } from 'react';
import './Dashboard.css';
import { useHistory } from 'react-router-dom';
import { apilink } from '../../data/fdata';
import axios from 'axios';
import Cookies from 'js-cookie';
import SideBar from '../../component/sidebar/SideBar';
import MyListC from '../../component/mylistcompo/MyListC';
import { useAlert } from 'react-alert';


const MyList = () => {
  const token = Cookies.get('_indiayour_seller_access_token');
  const his = useHistory();
  const alert = useAlert();

  const [productList, setProductList] = useState([]);
  

  const [pageloading, setPageLoading] = useState(false);



  useEffect(async() => {
    if(token) {
      setPageLoading(true)
        const res=await axios.get(`${apilink}/api/seller/authVerify`,{
            headers: {
              Authorization: token,
            },
          })
          if(!res.data.success)
          {
            Cookies.remove('_indiayour_seller_access_token');
           localStorage.removeItem('_indiayour_seller_access_login');
          console.clear();
          window.location.href = '/';
          }else{
            if(!res.data.userInfo.isApproved) {
                his.push("/business/add")
            }
          }
          setTimeout(() => {
            setPageLoading(false)
          }, 2000);
         
    }else{
      his.push("/")
    }
  }, []);


  const getProductsBySellerId=async()=>{
    const res=await axios.get(`${apilink}/api/product/getProductsBySellerId`,{
      headers: {
        Authorization: token,
      },
    })
    
    if(res.data.success)
    {
      // console.log(res.data)
      setProductList(res.data.result)

    }else{
      alert.error(res.data.msg)
    }
  }


  useEffect(()=>{
    getProductsBySellerId()
  },[])
  // console.log(productList)
  return (
    <>
      <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
            <div className="container-fluid">
              {/* <h3 className="text_Deco">My List</h3> */}
              <div className="row mt-5 p-2">
                <div className="col-lg-12 col-md-12 mb-3">
                  {
                    productList.length > 0 && <MyListC productList={productList} alert={alert} />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {
        pageloading && (
          <>
          <div className="pageloading">
          <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>

          </>
        )
      }
    </>
  );
};

export default MyList;
