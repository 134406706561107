import React, { useEffect } from 'react';
import './Dashboard.css';
import { NavLink, useHistory } from 'react-router-dom';
import { apilink } from '../../data/fdata';
import axios from 'axios';
import Cookies from 'js-cookie';
import SideBar from '../../component/sidebar/SideBar';
import { useState } from 'react';

const AccountDet = () => {
  const token = Cookies.get("_indiayour_seller_access_token");
  const [showValue, setShowValue] = useState("");

  const [statusBusiness, setStatusBusiness] = useState({});
  const his = useHistory();


  
  const [pageloading, setPageLoading] = useState(false);

  const getVerify = async () => {
    setPageLoading(true);
    const res = await axios.get(`${apilink}/api/seller/authVerify`, {
      headers: {
        Authorization: token,
      },
    });
    if (!res.data.success) {
      Cookies.remove("_indiayour_seller_access_token");
      localStorage.removeItem("_indiayour_seller_access_login");
      console.clear();
      window.location.href = "/";
    } else {
  
      // if(!res.data.userInfo.isApproved) {
      //   his.push("/business/add")
      //     }
// console.log(res.data.userInfo)

      setStatusBusiness(res.data.userInfo)
      
    }
    setTimeout(() => {
      setPageLoading(false);
    }, 2000);
  };

  useEffect(async () => {
    if (token) {
      getVerify();
    } else {
      his.push("/");
    }
  }, []);


  return (
    <>
      <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
            <div className="container-fluid">
              <div className="row ">
                <div className="col-12">
                  <div className="card p-3">
                    <h3 className="text_Deco mb-3">Account Details</h3>
                    <h5>Name : {statusBusiness?.comp_name && statusBusiness?.comp_name} </h5>
                    <h5>Email: {statusBusiness?.email && statusBusiness?.email}</h5>
                    <h5>Phone: {statusBusiness?.phone && statusBusiness?.phone}</h5>
                  </div>
                </div>
              </div>
              <div className="row mt-4 ">
                <div className="col-12">
                  <div className="card p-3">
                    <h3 className="text_Deco mb-3">Business Details</h3>
                    {statusBusiness?.businessDetails?.address ? (
                      <>
                        <h5>Business Address: {statusBusiness?.businessDetails?.address}, {statusBusiness?.businessDetails?.city}, {statusBusiness?.businessDetails?.pincode}, {statusBusiness?.businessDetails?.state}</h5>
                        <h5> Business Type: {statusBusiness?.businessDetails?.businessType}</h5>
                        <h5> Industry or Category:{statusBusiness?.businessDetails?.category}</h5>
                        <h5> Seller Description:{statusBusiness?.businessDetails?.sellerDet}</h5>
                        <h5> Team Size:{statusBusiness?.businessDetails?.teamsize}</h5>
                        <h5> GSTIN : {statusBusiness?.businessDetails?.gstinNo}  <small
                            onClick={() => setShowValue(statusBusiness?.businessDetails?.gstinFile
                              )}
                            className="cur text-custom"
                            data-toggle="modal"
                            data-target="#myModal"
                          >
                            View <i className="fa fa-external-link"></i>{" "}
                          </small> </h5>
                        <h5> PAN:{statusBusiness?.businessDetails?.panNo} <small
                            onClick={() => setShowValue(statusBusiness?.businessDetails?.panFile
                              )}
                            className="cur text-custom"
                            data-toggle="modal"
                            data-target="#myModal"
                          >
                            View <i className="fa fa-external-link"></i>{" "}
                          </small></h5>
                        <div className="text-right">
                          <NavLink to="/business/edit">
                            {' '}
                            <i className="fa fa-edit text-danger cur"></i>
                          </NavLink>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="text-left">
                          <NavLink
                            to="/business/add"
                            className="btn btn-primary"
                          >
                            Add Business
                          </NavLink>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="myModal">
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">View Document</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>

            <div class="modal-body">
              {/* {
              showValue ?  <img src={showValue} alt="" className='doc_img' /> : "loading..."
            } */}

              <img src={showValue} alt="" className="doc_img" />
            </div>
          </div>
        </div>
      </div>

      {pageloading && (
        <>
          <div className="pageloading">
            <div class="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AccountDet;
