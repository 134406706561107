import React from 'react';
import { NavLink } from 'react-router-dom';
import './Footer.css';
import { useContext } from 'react';
import { DataContext } from '../../context/DataContext';
const Footer = () => {
  const {  contactData } = useContext(DataContext);
  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-8 col-sm-8 col-12  mb-3">
              <h5><u>About Us</u></h5>
              <p className="p_mar">
              At Indiayour, we're your top B2B marketplace, simplifying machinery and raw material procurement. Empowering businesses with a user-friendly solution for all industrial needs.
              </p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-12  mb-3">
              <h5><u>Links</u></h5>
              <ul>
                <li>
                  <NavLink to="/account">
                    <i className="fa fa-user mr-2"></i> Account Details
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/setting">
                    <i className="fa fa-cog mr-2"></i> Account Setting
                  </NavLink>
                </li>
                <li>
                  <a href="https://indiayour.com/privacy-policy" target='_blank'>
                    <i className="fa fa-print mr-2"></i> Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="https://indiayour.com/terms-conditions" target='_blank'>
                    <i className="fa fa-server mr-2"></i> Terms & Conditions
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-md-12 col-12  mb-3">
              <h5><u>Contact Us</u></h5>
              <ul>
            <li>Email: <a className='fn_16' href={`mailto:${contactData.email}`}>{contactData.email}</a></li>
            <li>Phone: <a className='fn_16' href={`tel:${contactData.phone}`}>{contactData.phone}</a></li>
            <li> 
            <p className='m-0'>{contactData.address}</p></li>
           </ul>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-md-6 col-sm-12 mx-auto mb-3">
            <div className="social_icon_box">
                <div className="social_icon">
                  <a href={contactData.facebook} target='_blank'>
                    <i className="fa fa-facebook"></i>
                  </a>
                </div>
                <div className="social_icon">
                  <a href={contactData.linkedin} target='_blank'>
                    <i className="fa fa-linkedin"></i>
                  </a>
                </div>
                <div className="social_icon">
                  <a href={contactData.instagram} target='_blank'>
                    <i className="fa fa-instagram"></i>
                  </a>
                </div>
                <div className="social_icon">
                  <a href={contactData.twitter} target='_blank'>
                    <i className="fa fa-twitter"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 mx-auto mb-3">
              <div className="fright"><p className='text-center m-0'>Copyright © {new Date().getFullYear()} Indiayour. All Rights Reserved.</p></div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
