import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { useState } from 'react';
import parse from 'html-react-parser';
import axios from 'axios';
import { apilink, mainpath } from '../../data/fdata';
import Cookies from 'js-cookie';
import { useContext } from 'react';
import { DataContext } from '../../context/DataContext';
const MyListC = ({productList,alert}) => {
  const token = Cookies.get('_indiayour_seller_access_token');
  const {  removeSpecialCharacters } = useContext(DataContext);
  const [myData, setMyData] = useState(productList);
  const [pageNo, setPageNo] = useState(0);
  const [filter, setFilter] = useState("all");

  const perpage = 10;
  const pagevisit = pageNo * perpage;

  const dataall = myData.slice(pagevisit, pagevisit + perpage);
  const boxno = Math.ceil(myData.length / perpage);

  const likedChange = ({ selected }) => {
    setPageNo(selected);
  };
 

  const deleteProduct=async(id)=>{
    
    const res=await axios.get(`${apilink}/api/product/deleteProductById/${id}`,{
      headers: {
        Authorization: token,
      },
    })
    if(res.data.success){
      alert.success(res.data.msg)
      setMyData(productList.filter(product =>product._id!==id))

    }else{
      alert.error(res.data.msg)
    }

  }

  useEffect(()=>{

    if(filter=="all"){
      
      setMyData(productList);
    } else{
      setMyData(productList.filter(v=>v.isProApproved==filter));
    }

  },[filter])

  // console.log(myData)

  


  return (
    <>
      <div className="card p-3">
        <div className="dd_flex">
          <h5 className="text_Deco">My Listing</h5>
          <div>
            <div class="form-group">
              <select class="form-control filterform" id="sel1" value={filter} onChange={(e)=>setFilter(e.target.value)} >
                
                <option value="all">All</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
                <option value="pending">Pending</option>
              </select>
            </div>
          </div>
        </div>
        {
                dataall.length > 0 ? <>
        <div class="table-responsive ">
          <table class="table table-borderless">
            <thead>
              <tr>
                <th className='w_10'>Date</th>
                <th className="tr_w">Product Name</th>
                <th className='w_10'>Price</th>
                <th className='w_10'>Qty</th>
                <th className='w_10'>Status</th>
                <th className='w_10'>Operation</th>
              </tr>
            </thead>
            <tbody>
             
                
                
                {dataall.map((val, ind) => {
                return (
                  <>
                    <tr className="">
                    <td className="">{new Date(val.createdAt).toLocaleDateString('en-GB')}</td>
                      <td className="tr_ww">
                        <div className="small_det">
                          <img src={val.pro_images[0]} alt="" />

                          <div>
                            <p>
                              {val.pro_title}
                            </p>
                            <span
                              class="badge badge-primary m-1 cur"
                             
                              data-toggle="modal"
                              data-target={`#myModal${val._id}`}
                            >
                              View
                            </span>
                            <NavLink to={`/edit/${val._id}`} class="badge badge-success m-1 cur">
                              Edit
                            </NavLink>
                            <a href={`${mainpath}/product/${removeSpecialCharacters (val.pro_title)?.trim().toLowerCase().replace(/\s/g, "-")}/${val._id}/DEMO`} target="_blank" class="badge badge-dark m-1 ">Link</a>
                          </div>
                        </div>
                        <div class="modal fade" id={`myModal${val._id}`}>
                          <div class="modal-dialog modal-lg modal-dialog-centered">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h4 class="modal-title">Product Details</h4>
                                <button
                                  type="button"
                                  class="close"
                                  data-dismiss="modal"
                                >
                                  &times;
                                </button>
                              </div>

                              <div class="modal-body">
                                <div className="row">
                                  <div className="col-md-6 col-sm-12 col-12 mx-auto mb-3">
                                   <div className="img_flex">
                                   {
                  val.pro_images?.map((va,indd)=>{
                    return (
                      <div className="img_flex_item">
                  <img src={va} alt="" />
                </div>
                    )
                  })
                }
                                   </div>

                                  </div>
                                  <div className="col-md-6 col-sm-12 col-12 mx-auto mb-3">
                                    <h3>{val.pro_title}</h3>
                                    <p><b>{val.pro_category}-{val.pro_subcategory}</b></p>
                                    <p className="m-0 prodesc">
                                      {parse(val.pro_description)}
                                    </p>
                                    <h5>Actual Price: ₹{val.pro_price}</h5>
                                    <h5>{val.pro_discount}% Discount Price : ₹{ Math.ceil(val.pro_price - (val.pro_price*(val.pro_discount/100)))} </h5>
                                    
                                    <h5>Qty: {val.pro_qty}</h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>₹{val.pro_price}</td>
                      <td>{val.pro_qty}</td>
                      <td>
                      {
                          val.isProApproved =="pending" &&  <span class="badge badge_w badge-warning m-1">Pending</span>
                        }
                       {
                          val.isProApproved =="approved" && <span class="badge badge-success m-1">Approved</span>
                       }
                       {
                        val.isProApproved =="rejected" &&  <span class="badge badge-danger m-1">Rejected</span>
                       }
                      </td>
                      <td>
                        <span class="badge badge-warning m-1 cur" onClick={()=>{
                          if(window.confirm("Want to delete?"))
                          {
                            deleteProduct(val._id)
                          }
                          
                        }}>Delete</span>
                      </td>
                    </tr>
                  </>
                );
              })}
                
            </tbody>
          </table>
        </div>
        </>:<>
                
                
                <div className="text-center py-3">
                  <h5>No Data Found</h5>
                </div>
                </>
              }
        <div className="row pt-2">
         <div className="col-12">
         {
          dataall.length > 0 &&  <ReactPaginate
          previousLabel={'Prev'}
          nextLabel={'Next'}
          pageCount={boxno}
          onPageChange={likedChange}
          containerClassName={'pagination'}
          // previousLinkClassName={"prevbutton"}
          // nextLinkClassName={"nextbutton"}
          // disabledClassName={"pagedisable"}
          activeClassName={'activebutton'}
        />
         }
         </div>
        </div>
      </div>
    </>
  );
};

export default MyListC;
