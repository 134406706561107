import React, { useContext, useEffect, useState } from 'react';
import '../dashboard/Dashboard.css';
import { NavLink, useHistory } from 'react-router-dom';
import { apilink } from '../../data/fdata';
import axios from 'axios';
import Cookies from 'js-cookie';
import SideBar from '../../component/sidebar/SideBar';

import { Chart as ChartJs, Tooltip, Title, ArcElement, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import Request from '../../component/homecompo/Request';
import MyListCompo from '../../component/homecompo/MyListCompo';
import { DataContext } from '../../context/DataContext';
import { useAlert } from 'react-alert';
import Piechart from '../../component/chartcom/Piechart';
// ChartJs.register(Tooltip, Title, ArcElement, Legend);




const Home = () => {
  const alert = useAlert();

  const token = Cookies.get('_indiayour_seller_access_token');
  const his = useHistory();
  const [productList, setProductList] = useState([]);
  const [userData, setUserData] = useState([]);
  const [myData, setMyData] = useState([]);
  // const [mySellData, setMySellData] = useState([]);
  const [mySellData, setMySellData] = useState({});



  
    const [pageloading, setPageLoading] = useState(false);
  
  useEffect(async() => {
    if(token) {
      setPageLoading(true)
        const res=await axios.get(`${apilink}/api/seller/authVerify`,{
            headers: {
              Authorization: token,
            },
          })
          if(!res.data.success)
          {
            Cookies.remove('_indiayour_seller_access_token');
           localStorage.removeItem('_indiayour_seller_access_login');
          console.clear();
          window.location.href = '/';
          }else{
            if(!res.data.userInfo.isApproved) {
                his.push("/business/add")
            }
            setUserData(res.data.userInfo)

          }
          setTimeout(() => {
            setPageLoading(false)
          }, 2000);
         
    }else{
      his.push("/")
    }
  }, []);


  


  const getProductsBySellerId=async()=>{
    const res=await axios.get(`${apilink}/api/product/getProductsBySellerId`,{
      headers: {
        Authorization: token,
      },
    })
    
    if(res.data.success)
    {
      // console.log(res.data)
      setProductList(res.data.result)

    }else{
      // alert.error(res.data.msg)
    }
  }

  const getSellerOrderRequest=async()=>{
    const res=await axios.get(`${apilink}/api/order/getSellerOrderRequest`,{
      headers: {
        Authorization: token,
      },
    })
    // console.log(res.data.result)
   if(res.data.success)
   {
    setMyData(res.data.result)
   

   }
  }

  const getOrderProductCategoryforSeller=async()=>{
    const res=await axios.get(`${apilink}/api/order/getOrderProductCategoryforSeller`,{
      headers: {
        Authorization: token,
      },
    })
// console.log(res.data)
    if(res.data.success)
   {
    
    setMySellData({
      type:"totalQuantity",
      data:res.data.result
    })
   

   }
    
  }

  useEffect(()=>{
    getProductsBySellerId()
    getSellerOrderRequest()
    getOrderProductCategoryforSeller()
  },[])

  return (
    <>
      <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12 mb-3">
                <div className="red_card">
                  <div className="media">
                    <h2>Hello, {userData?.email && userData?.email.split("@")[0] }</h2>
                    <p>Welcome back, your dashboard is ready!</p>
                    <NavLink to="/additem" class="btn btn-outline-dark">
                      Get Started <i className="fa fa-long-arrow-right"></i>
                    </NavLink>
                  </div>
                  <div className="cartoon_img">
                    <img src="./image/welcome.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3 dash">
              <div className="col-md-4 col-sm-6 col-12 mb-3">
                <div className="card p-2">
                  <h4>Total Products</h4>
                  {
                    productList.length > 0 ? <h1>{productList.length}</h1> : <h1>0</h1>
                  }
                  
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12 mb-3">
                <div className="card p-2">
                  <h4>Order Requests</h4>
                  <h1>{myData.length}</h1>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12 mb-3">
                <div className="card p-2">
                  <h4>Happy Clients</h4>
                  <h1>{myData.filter((v)=>v.orderStatus=="Delivered")?.length}</h1>
                </div>
              </div>
            </div>
            <div className="row mt-3 ">
              <div className="col-lg-8 col-md-12 mb-3">
               {
                productList.length > 0 ?  <MyListCompo productList={productList} alert={alert} /> :(
                  <>
                  
                  <div className="card cardh">
                  <div className="text center p-3">
                    <p>No Item Found</p>
                  </div>
                  </div>
                  </>
                )
               }
              </div>
              <div className="col-lg-4 col-md-12 mb-3">
                <div className="card p-3">
                  <p className='text-center mb-3'>Category & Product Sell Graph</p >
                 {
                  mySellData?.data?.length > 0 ? (<>

                    <Piechart data ={mySellData} />
                  
                  </>):(
                    <>
                    <div className="text-center">
                      <div className="p-3">
                        <p>No item Found</p>
                      </div>
                    </div>
                    </>
                  )
                 }
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <Request comtype={"SMALL"} />
            </div>
          </div>
        </div>
      </div>

      {
        pageloading && (
          <>
          <div className="pageloading">
          <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>

          </>
        )
      }
    </>
  );
};

export default Home;
