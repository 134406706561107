// export const apilink = 'http://localhost:5000';
// export const path = 'http://localhost:4501';
// export const mainpath="http://localhost:4551"



export const mainpath="https://indiayour.com"
export const apilink = 'https://api.indiayour.com'
export const path = 'https://seller.indiayour.com';


export const headerNav = [
    {
      display: 'Dashboard',
      path: '/home',
    },
    {
      display: 'My Listing',
      path: '/mylist',
    },
    {
      display: 'Add Item',
      path: '/additem',
    },
    {
      display: 'Order Requests',
      path: '/order-requests',
    },
    {
        display: 'Product Sell Details',
        path: '/product-sell-details',
    }
  ];

  export const generateRandomColor=() =>{
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }


  export const discountPriceFunc=(price,disc)=>{
    let discount = (disc * price) / 100;
    let  discountedAmount=(Math.ceil(price-discount))
    return discountedAmount;
  }
export const gstPriceFunc=(price, discount , gst)=>{
  let total= discountPriceFunc(price,discount)
  
 let GSTamount= Math.ceil((total*gst)/100)
//  console.log(total , GSTamount)
  return total+GSTamount;

}