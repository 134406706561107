import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import './Auth.css';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Cookies from 'js-cookie';
import { apilink, path } from '../../data/fdata';
import { useAlert } from 'react-alert';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import AOS from 'aos';
import 'aos/dist/aos.css'
import { useContext } from 'react';
import { DataContext } from '../../context/DataContext';

const LoginPage = () => {
  const login_token = Cookies.get('_indiayour_seller_access_token');
  const [logstatus, setLogStatus] = useState(false);
  const {  contactData } = useContext(DataContext);
  const alert = useAlert();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [ptype, setPtype] = useState("password");

  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('');
  const his = useHistory();

  const onSub = async (e) => {
    e.preventDefault();
    setLoading(true);
    const res=await axios.post(`${apilink}/api/seller/login`,{
      email,password
    })
    // console.log(res.data)

    if(res.data.success)
    {
      
      localStorage.setItem('_indiayour_seller_access_login', true);
      
      Cookies.set('_indiayour_seller_access_token', res.data.access_token, {
        expires: 1,
      });

      // //   his.push('/employers/account');
      // window.location.href = '/employers/account';
      
      if(!res.data.user.isApproved)
      {
       setTimeout(() => {
        alert.success("Login success")
        setLoading(false);
        
       }, 2000);
       setTimeout(() => {
        window.location.href = '/business/add';
       }, 3000);
      }else{
        setTimeout(() => {
          alert.success("Login success")
          setLoading(false);
         
         }, 2000);

         setTimeout(() => {
          window.location.href = '/home';
         }, 3000);
        
      }

    }else{
      setTimeout(() => {
        setMsg(res.data.msg);
      setStatus(true);
      setLoading(false);
      }, 2000);
    }


    
  };

  useEffect(() => {
    AOS.init();
  }, [])

  useEffect(async() => {
    // console.log(login_token)
    if(login_token) {
      
      const res=await axios.get(`${apilink}/api/seller/authVerify`,{
          headers: {
            Authorization: login_token,
          },
        })
        // console.log(res.data)
        if(!res.data.success)
        {
          Cookies.remove("_indiayour_seller_access_token");
      localStorage.removeItem("_indiayour_seller_access_login");
      console.clear();
      setLogStatus(false)
        }else{
         
          setLogStatus(true)


        }
       
  }else{
    setLogStatus(false)
  }
  }, []);

  return (
    <>
    <div className="login_page">
     <div className="container mt-4">
     <div className="row">
     <div className=" col-lg-6 col-md-12 mx-auto mb-3 ">
        <div className="text_div">
        <h1 className='font-weight-bold' data-aos="fade-up"
                        data-aos-duration="2000">Welcome to Indiayour</h1>
<p data-aos="fade-up"
                        data-aos-duration="2500">Boost your business and expand your reach with India's premier ecommerce platform for businesses.</p>
<div className="text-left" data-aos="fade-up"
                        data-aos-duration="3000">
{logstatus ?<NavLink to="/home"  className='btn btn-light px-5 py-2'>Get Started &nbsp; <i class="fa fa-long-arrow-right" aria-hidden="true"></i></NavLink> :<a href="https://indiayour.com/all-categories" target='_blank' className='btn btn-light px-5 py-2'>Get Started &nbsp; <i class="fa fa-long-arrow-right" aria-hidden="true"></i></a>}
</div>
        </div>

      </div>
      <div className=" col-lg-6 col-md-12 mx-auto mb-3">
        {
          logstatus ? (
            <>
            <img data-aos="fade-up"
                        data-aos-duration="2500" src={`${path}/image/Business_deal-pana.png`} alt="" className='log_img' />
            </>
          ):(
            <>
             <div className="card p-3 text-dark" data-aos="fade-up"
                        data-aos-duration="2500">
        {status ? (
                  <>
                    <div class="alert alert-warning alert-dismissible">
                      <button
                        type="button"
                        class="close"
                        data-dismiss="alert"
                        onClick={() => setStatus(false)}
                      >
                        &times;
                      </button>
                      {msg}
                    </div>
                  </>
                ) : null}
                 <img
                    src={`${path}/image/Indiayour.png`} alt=""
                    className="logoimg" />
                <h3 className="text-center pb-3">Indiayour Signin</h3>
                <br />
          <form onSubmit={onSub} className="">
                  <div class="form-group">
                    <input
                      type="email"
                      placeholder="Enter Email"
                      class="form-control formh"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div class="form-group passform">
                    <input
                      type={ptype}
                      placeholder="Enter Password"
                      class="form-control formh"
                      name="lname"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    
                    <div>
                    {ptype == 'password' ?  <i className='fa fa-eye-slash cur' onClick={()=>setPtype("text")}></i> :  <i className='fa fa-eye cur' onClick={()=>setPtype("password")}></i> }
                  </div>
                  </div>

                  <div className="authdflex text-dark">
                    <p
                    className='m-0'
                      onClick={() => his.push('/register')}
                      style={{ cursor: 'pointer' }}
                    >
                      Don't Have an Account?
                    </p>
                    <p
                    className='m-0'
                      onClick={() => his.push('/forget-password')}
                      style={{ cursor: 'pointer' }}
                    >
                      Forget Password?
                    </p>
                    </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className={
                        loading ? 'dis btn btn-primary' : 'btn btn-primary'
                      }
                      disabled={loading}
                    >
                      Login Now
                    </button>
                  </div>
                  {loading && (
                    <div className="text-center p-2">
                      <CircularProgress color="error" size={35} />
                    </div>
                  )}
                </form>
        </div>
            
            </>
          )
        }
       
      </div>
     </div>
     </div>
    </div>
    <section className='page_sec'>
    <div className="container" >
    <h2 className='text-custom mb-5 text-center' data-aos="fade-up"
                        data-aos-duration="2000">WHY US?</h2>
    <div className="row">
    <div className="col-lg-6 col-md-12 col-12 mb-3">
<img src={`${path}/image/ab.png`} alt="" data-aos="fade-up"
                        data-aos-duration="2000" className='ab_img' />
</div>
      <div className="col-lg-6 col-md-12 col-12 mb-3">

<ul className='pl-5' data-aos="fade-up"
                        data-aos-duration="2000">
  <li className='mb-2' >At Indiayour, we aim to be a distinctive e-commerce platform, specializing in utility products for various industrial needs.</li>
  <li className='mb-2'>Our goal is to establish ourselves as a 'trusted seller' hub, providing a secure platform for our sellers to expand their businesses.</li>
  <li className='mb-2'>As your selling partner, we have experienced significant growth and aspire to capture businesses across India.</li>
  <li>  Our website features a meticulously curated collection of over 1000+ SKUs from globally recognized brands, making us a trusted source for industrial requisites.</li>
</ul>
      </div>
      
    </div>
    </div>
    </section>
    <section className='pt-5'>
    <div className="container">
     
    <div className="row">
      <div className="col-lg-6 col-md-12 col-12 mb-3">
      <h2 className='text-custom  ' data-aos="fade-up"
                        data-aos-duration="2000">HOW IT WORKS?</h2>
      <p data-aos="fade-up"
                        data-aos-duration="2500">Becoming a seller with us is a straightforward process.</p>
<br />
        <div className='step_box' data-aos="fade-up"
                        data-aos-duration="2500">
<div className="step_box_img">
  <img src={`${path}/image/Mobile_login-pana.svg`} alt="" />
</div>
<div className='ml-4'>
  <h5>CREATE ACCOUNT</h5>
  <p className='m-0 fn_14'>To become a seller, simply enter your Phone No , Email Address , GSTIN and Pan No to create an account</p>
</div>
        </div>
        <div className='step_box' data-aos="fade-up"
                        data-aos-duration="2500">
<div className="step_box_img">
  <img src={`${path}/image/Catalogue-pana.svg`} alt="" />
</div>
<div className='ml-4'>
  <h5>LIST PRODUCTS</h5>
  <p className='m-0 fn_14'>Use your supplier panel to list the products you wish to sell.</p>
</div>
        </div>
        <div className='step_box' data-aos="fade-up"
                        data-aos-duration="2500">
<div className="step_box_img">
  <img src={`${path}/image/Mobile_login-pana.svg`} alt="" />
</div>
<div className='ml-4'>
  <h5>GET ORDERS</h5>
  <p className='m-0 fn_14'>You are now set to receive orders from a vast customer base actively shopping on our platform.</p>
</div>
        </div>

        <div className='step_box' data-aos="fade-up"
                        data-aos-duration="2500">
<div className="step_box_img">
  <img src={`${path}/image/Take_Away-rafiki.svg`} alt="" />
</div>
<div className='ml-4'>
  <h5>FREE- FREIGHT SERVICE</h5>
  <p className='m-0 fn_14'>Enjoy complimentary warehouse pick-up and shipping.</p>
</div>
        </div>
        <div className='step_box' data-aos="fade-up"
                        data-aos-duration="2500">
<div className="step_box_img">
  <img src={`${path}/image/Payment_Information-bro.svg`} alt="" />
</div>
<div className='ml-4'>
  <h5>ACCEPT PAYMENTS</h5>
  <p className='m-0 fn_14'>Accept payments and successfully sell your products.</p>
</div>
        </div>

      </div>
      <div className="col-lg-6 col-md-12 col-12 mb-3">
<img src={`${path}/image/Partnership-amico.svg`} data-aos="fade-up"
                        data-aos-duration="2500" alt="" className='b2bimg mt-5' />
</div>
    </div>
    </div>
    </section>
    <br />
    <section className='foot_sec'>
    <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-8 col-12  mb-3">
              <h5><u>About Us</u></h5>
              <p className="p_mar">
              At Indiayour, we're your top B2B marketplace, simplifying machinery and raw material procurement. Empowering businesses with a user-friendly solution for all industrial needs.
              </p>
            </div>
            <div className="col-lg-4 col-md-4 col-12  mb-3">
              <h5><u>Links</u></h5>
              <ul>
                <li>
                  <NavLink to="/account">
                     Account Details
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/setting">
                     Account Setting
                  </NavLink>
                </li>
                <li>
                  <a href="https://indiayour.com/privacy-policy" target='_blank'>
                     Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="https://indiayour.com/terms-conditions" target='_blank'>
                     Terms & Conditions
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-md-12 col-12  mb-3">
            <h5><u>Contact Us</u></h5>
              
              <ul>
              <li>Email: <a className='fn_16' href={`mailto:${contactData.email}`}>{contactData.email}</a></li>
            <li>Phone: <a className='fn_16' href={`tel:${contactData.phone}`}>{contactData.phone}</a></li>
            <li> 
            <p className='m-0'>{contactData.address}</p></li>
               
              </ul>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-md-6 col-sm-12 mx-auto mb-3">
            <div className="social_icon_box">
                <div className="social_icon">
                  <a href={contactData.facebook} target='_blank'>
                    <i className="fa fa-facebook"></i>
                  </a>
                </div>
                <div className="social_icon">
                  <a href={contactData.linkedin} target='_blank'>
                    <i className="fa fa-linkedin"></i>
                  </a>
                </div>
                <div className="social_icon">
                  <a href={contactData.instagram} target='_blank'>
                    <i className="fa fa-instagram"></i>
                  </a>
                </div>
                <div className="social_icon">
                  <a href={contactData.twitter} target='_blank'>
                    <i className="fa fa-twitter"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 mx-auto mb-3">
              <div className="fright"><p className='text-center m-0'>Copyright © {new Date().getFullYear()} Indiayour. All Rights Reserved.</p></div>
            </div>
          </div>
        </div>
    </section>
    </>
  )
}

export default LoginPage