import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { apilink } from "../../data/fdata";
import axios from "axios";
import Cookies from "js-cookie";
import SideBar from "../../component/sidebar/SideBar";
import { CategoryList, stateList, teamList } from "../../data/stateList";
import { useAlert } from "react-alert";
import { CircularProgress } from "@mui/material";

const Business = () => {
  const alert = useAlert();
  const token = Cookies.get("_indiayour_seller_access_token");
  const [address, setAddress] = useState("");
  const [pin, setPin] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [category, setCategory] = useState("");
  const [btype, setBType] = useState("");
  const [description, setDescription] = useState("");
  const [teamSize, setTeamSize] = useState("");
  const [gstin, setGstin] = useState("");
  const [pan, setPan] = useState("");
  const [gstfile, setGstfile] = useState([]);
  const [panfile, setPanfile] = useState([]);

  const [gstLink, setGstLink] = useState("");
  const [panLink, setPanLink] = useState("");
  const [showValue, setShowValue] = useState("");

  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  const [msg, setMsg] = useState("");

  const his = useHistory();
  // const { type } = useParams();
  const [accountApproved, setAccountApproved] = useState(true);
  const [pageloading, setPageLoading] = useState(false);

  const getVerify = async () => {
    setPageLoading(true);
    const res = await axios.get(`${apilink}/api/seller/authVerify`, {
      headers: {
        Authorization: token,
      },
    });
    if (!res.data.success) {
      Cookies.remove("_indiayour_seller_access_token");
      localStorage.removeItem("_indiayour_seller_access_login");
      console.clear();
      window.location.href = "/";
    } else {
      // add data
      if (res.data.userInfo.isApproved) {
        setAccountApproved(false);
      }
      setAddress(res.data.userInfo?.businessDetails?.address);
      setCity(res.data.userInfo?.businessDetails?.city);
      setState(res.data.userInfo?.businessDetails?.state);
      setPin(res.data.userInfo?.businessDetails?.pincode);
      setBType(res.data.userInfo?.businessDetails?.businessType);
      setCategory(res.data.userInfo?.businessDetails?.category);
      setDescription(res.data.userInfo?.businessDetails?.sellerDet);
      setTeamSize(res.data.userInfo?.businessDetails?.teamsize);
      setGstin(res.data.userInfo?.businessDetails?.gstinNo);
      setPan(res.data.userInfo?.businessDetails?.panNo);
      setGstLink(res.data.userInfo?.businessDetails?.gstinFile);
      setPanLink(res.data.userInfo?.businessDetails?.panFile);
    }
    setTimeout(() => {
      setPageLoading(false);
    }, 2000);
  };

  useEffect(async () => {
    if (token) {
      getVerify();
    } else {
      his.push("/");
    }
  }, []);

  const onSub = async (e) => {
    e.preventDefault();
    setLoading(true)

    if (gstfile?.name && panfile?.name) {
      let formData = new FormData();
      formData.append("file", gstfile);
      const res = await axios.post(`${apilink}/api/file/upload`, formData, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: token,
        },
      });

      // console.log(res.data.result)

      if (res.data.success) {
        let formDat = new FormData();
        formDat.append("file", panfile);
        const ress = await axios.post(`${apilink}/api/file/upload`, formDat, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: token,
          },
        });
        // console.log(ress.data.result)

        if (ress.data.success) {
          const data = {
            address,
            city,
            state,
            pincode: pin,
            businessType: btype,
            category,
            sellerDet: description,
            teamsize: teamSize,
            gstinNo: gstin,
            gstinFile: res.data.result[0].Location,
            panNo: pan,
            panFile: ress.data.result[0].Location,
          };

          const result = await axios.post(
            `${apilink}/api/seller/updateBusinessDetails`,
            data,
            {
              headers: {
                Authorization: token,
              },
            }
          );

          // console.log(result.data)

          if (result.data.success) {
            setAddress("");
      setCity("");
      setState("");
      setPin("");
      setBType("");
      setCategory("");
      setDescription("");
      setTeamSize("");
      setGstin("");
      setPan("");
      setGstfile([]);
      setPanfile([])
            alert.success("Business Details Added Successfully")
            setTimeout(() => {
              getVerify();
            }, 2000);
          } else {
            alert.error(res.data.msg);
          }
        } else {
          alert.error(res.data.msg);
        }
      } else {
        alert.error(res.data.msg);
      }
    } else {
      if (!gstfile?.name) {
        alert.error("Upload GST file with proper file size & extension");
      }
      if (!panfile?.name) {
        alert.error("Upload Pan file with proper file size & extension");
      }
    }

    setLoading(false)
  };

  const handelFile = (e, va) => {
    const { files } = e.target;
    // console.log(files[0]); files[0].type === 'application/pdf'
    if (files.length > 0) {
      if (files[0].size > 2000000) {
        alert.error(`${va} File should be less then 2 MB`);
        if (va == "pan") {
          setPanfile([]);
        } else {
          setGstfile([]);
        }
      } else if (
        files[0].type === "image/jpeg" ||
        files[0].type === "image/jpg" ||
        files[0].type === "image/png"
      ) {
        if (va == "pan") {
          setPanfile(files[0]);
        } else {
          setGstfile(files[0]);
        }
      } else {
        if (va == "pan") {
          setPanfile([]);
        } else {
          setGstfile([]);
        }
        alert.error(`${va} File extension should be only jpg, png , jpeg`);
      }
     
    }
  };

  return (
    <>
      <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
            <div className="container-fluid">
              {accountApproved && (
                <>
                  <div class="alert alert-warning alert-dismissible">
                        <button
                          type="button"
                          class="close"
                          data-dismiss="alert"
                        >
                          &times;
                        </button>
                        Add business details and admin will approve soon
                      </div>
                </>
              )}

              <h3 className="text_Deco">
                Add Business Details
              </h3>
              <div className="row mt-5 p-2">
                <div className="col-12">
                  {status ? (
                    <>
                      <div class="alert alert-warning alert-dismissible">
                        <button
                          type="button"
                          class="close"
                          data-dismiss="alert"
                          onClick={() => setStatus(false)}
                        >
                          &times;
                        </button>
                        {msg}
                      </div>
                    </>
                  ) : null}
                  <form onSubmit={onSub}>
                    <div class="form-row">
                      <div className="form-group col-lg-6 col-md-6">
                        <input
                          type="text"
                          placeholder="Enter Address"
                          className="form-control form_h"
                          name="address"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          required
                        />
                      </div>

                      <div className="form-group col-lg-6 col-md-6">
                        <input
                          type="text"
                          placeholder="Enter City"
                          className="form-control form_h"
                          name="city"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-lg-6 col-md-6">
                        <select
                          class="form-control form_h"
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                          required
                        >
                          <option selected hidden value="">
                            --Choose State--
                          </option>
                          {stateList?.map((val, ind) => {
                            return (
                              <>
                                <option key={ind} value={val.state}>
                                  {val.state}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </div>
                      <div class="form-group col-lg-6 col-md-6">
                        <input
                          type="number"
                          class="form-control form_h"
                          placeholder="Enter Pincode"
                          name="pin"
                          value={pin}
                          onChange={(e) => setPin(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div class="form-group col-lg-6 col-md-6">
                        <input
                          type="text"
                          class="form-control form_h"
                          placeholder="Enter Business Type"
                          name="btype"
                          value={btype}
                          onChange={(e) => setBType(e.target.value)}
                          required
                        />
                      </div>
                      <div className="form-group col-lg-6 col-md-6">
                        <select
                          class="form-control form_h"
                          value={category}
                          onChange={(e) => setCategory(e.target.value)}
                          required
                        >
                          <option selected hidden value="">
                            --Choose Category--
                          </option>
                          {CategoryList?.map((val, ind) => {
                            return (
                              <>
                                <option key={ind} value={val.title}>
                                  {val.title}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="form-row">
                      <div class="form-group col-lg-6 col-md-6">
                        <div class="form-group">
                          <textarea
                            class="form-control"
                            rows="3"
                            placeholder="Enter Seller Description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            required
                          ></textarea>
                        </div>
                      </div>
                      <div className="form-group col-lg-6 col-md-6">
                        <select
                          class="form-control form_h"
                          value={teamSize}
                          onChange={(e) => setTeamSize(e.target.value)}
                          required
                        >
                          <option selected hidden value="">
                            --Choose TeamSize-
                          </option>
                          {teamList?.map((val, ind) => {
                            return (
                              <>
                                <option key={ind} value={val.title}>
                                  {val.title}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    {!gstLink && <small>Enter GSTIN & Upload File</small>}
                    <div class="form-row">
                      <div className="form-group col-lg-6 col-md-6">
                        {gstLink && <small>Enter GSTIN & Upload File</small>}
                        <input
                          type="text"
                          placeholder="Enter GSTIN"
                          className="form-control form_h"
                          name="gstin"
                          value={gstin}
                          onChange={(e) => setGstin(e.target.value)}
                          required
                        />
                      </div>

                      <div className="form-group col-lg-6 col-md-6">
                        {gstLink && (
                          <small
                            onClick={() => setShowValue(gstLink)}
                            className="cur text-custom"
                            data-toggle="modal"
                            data-target="#myModal"
                          >
                            View <i className="fa fa-external-link"></i>{" "}
                          </small>
                        )}
                        <input
                          type="file"
                          className="form-control form_h"
                          name="gstinfile"
                          accept=".png, .jpeg , .jpg , .pdf"
                          onChange={(e) => handelFile(e, "gst")}
                          required
                        />
                      </div>
                    </div>
                    {!panLink && <small>Enter PAN No. & Upload File</small>}
                    <div class="form-row">
                      <div className="form-group col-lg-6 col-md-6">
                        {panLink && <small>Enter PAN No. & Upload File</small>}
                        <input
                          type="text"
                          placeholder="Enter PAN No."
                          className="form-control form_h"
                          name="pan"
                          value={pan}
                          onChange={(e) => setPan(e.target.value)}
                          required
                        />
                      </div>

                      <div className="form-group col-lg-6 col-md-6">
                        {panLink && (
                          <small
                            onClick={() => setShowValue(panLink)}
                            className="cur text-custom"
                            data-toggle="modal"
                            data-target="#myModal"
                          >
                            View <i className="fa fa-external-link"></i>{" "}
                          </small>
                        )}

                        <input
                          type="file"
                          className="form-control form_h"
                          name="panfile"
                          accept=".png, .jpeg , .jpg , .pdf"
                          onChange={(e) => handelFile(e, "pan")}
                          required
                        />
                      </div>
                    </div>

                    {
                      gstLink.length==0 && <div className="text-right">
                      <button
                        type="submit"
                        className={
                          loading
                            ? "dis btn btn-primary pl-5 pr-5"
                            : "btn btn-primary pl-5 pr-5"
                        }
                        disabled={loading}
                      >
                        Submit
                      </button>
                    </div>
                    }
                    {loading && (
                      <div className="text-center p-2">
                        <CircularProgress color="error" size={35} />
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="myModal">
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">View Document</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>

            <div class="modal-body">
              {/* {
              showValue ?  <img src={showValue} alt="" className='doc_img' /> : "loading..."
            } */}

              <img src={showValue} alt="" className="doc_img" />
            </div>
          </div>
        </div>
      </div>

      {pageloading && (
        <>
          <div className="pageloading">
            <div class="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Business;
