import React, { useContext, useEffect } from 'react';
import './Dashboard.css';
import { useHistory } from 'react-router-dom';
import { apilink } from '../../data/fdata';
import axios from 'axios';
import Cookies from 'js-cookie';
import SideBar from '../../component/sidebar/SideBar';
import { useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useAlert } from 'react-alert';
import CKeditorCom from './CKeditorCom';
import { CategoryList,placelist } from '../../data/stateList';
import AllTag from '../../component/tag/AllTag';
import { DataContext } from '../../context/DataContext';
import JoditEditorComp from '../../component/tag/JoditEditorComp';

const AddItem = () => {
  const token = Cookies.get('_indiayour_seller_access_token');

  const alert = useAlert();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [quantity, setQuantity] = useState('');
  const [price, setPrice] = useState('');
  const [imageList, setImageList] = useState([]);
  const [pdfList, setPdfList] = useState([]);
 
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');

  const [categoryid, setCategoryid] = useState('');
  const [subCategoryid, setSubCategoryid] = useState('');

  const [subCategoryList, setSubCategoryList] = useState([]);

  const [place, setPlace] = useState([]);
  const [discount, setDiscount] = useState(''); 
  const [pro_gst_percentage, setPro_gst_percentage] = useState();
  const [pro_sac_code, setPro_sac_code] =useState("")
  const [pdfLink, setPdfLink] = useState('');
  const { categoryList  } = useContext(DataContext);

  

  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('');
  const [addAll,setAddAll] = useState(true);

  const his = useHistory();


  const [pageloading, setPageLoading] = useState(false);
  
  useEffect(async() => {
    if(token) {
      setPageLoading(true)
        const res=await axios.get(`${apilink}/api/seller/authVerify`,{
            headers: {
              Authorization: token,
            },
          })
          // console.log(res.data)
          if(!res.data.success)
          {
            Cookies.remove('_indiayour_seller_access_token');
           localStorage.removeItem('_indiayour_seller_access_login');
          console.clear();
          window.location.href = '/';
          }else{
            if(!res.data.userInfo.isApproved) {
                his.push("/business/add")
            }
          }
          setTimeout(() => {
            setPageLoading(false)
          }, 2000);
         
    }else{
      his.push("/")
    }
  }, []);


  
  const onAdd = async (e) => {
    e.preventDefault();
    setLoading(true)
    // console.log("data")
    if(imageList.length > 0) {
      // console.log(imageList)
      let formData = new FormData();
      
      Array.from(imageList).forEach(image => {
        formData.append("file", image);
    });
      // console.log(formData);
      const res = await axios.post(`${apilink}/api/file/upload`, formData, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: token,
        },
      });
      
      if(res.data.success){

        let imgarr=[];
        res.data.result.forEach(img=>{
          imgarr.push(img.Location);
        })

        const data={
          pro_title:title,
          pro_qty:quantity,
          pro_description:description,
          pro_price:price,
          pro_discount:discount,
          pro_category:category,
          pro_category_id:categoryid,
          pro_subcategory:subCategory,
          pro_subcategory_id:subCategoryid,
          pro_place:place,
          pro_images:imgarr,
          pro_gst_percentage,
        pro_sac_code,
        pro_pdf_link:pdfLink
    
        }
        
        const ress=await axios.post(`${apilink}/api/product/uploadProduct`,data,{
          headers: {
            
            Authorization: token,
          },
        })

        if(ress.data.success) 
        {
          
        alert.success(res.data.msg)

        setTitle("")
        setCategory("")
        setPrice("")
        setDescription("")
        setDiscount("")
        setImageList([])
        setPrice("")
        setSubCategory("")
        setQuantity("")
        setPlace([])
        setPro_sac_code("")
        setPro_gst_percentage("")
setPdfLink("")
        setTimeout(() => {
          his.push("/mylist")
        }, 2000);

        }else{
          
        alert.error(res.data.msg)
        }




      }else{
       
        alert.error(res.data.msg)
      }
    }

       
   
    setLoading(false)
  };

  const handelimage = (e) => {
    const { files } = e.target;
    let f = false;
    let filetype = ['image/jpeg', 'image/jpg', 'image/png'];
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        // console.log(files[i].type);
        if (files[i].size > 2000000) {
          f = true;
          // console.log('ok');
        }

        if (!filetype.includes(files[i].type)) {
          f = true;
          // console.log('hi');
        }
      }
      if (f) {
        // console.log(f);
        alert.error(
          'Upload images with proper file size (2MB) & extension ( jpg, png , jpeg)'
        );
        f = false;
      }else{
        // console.log();
        setImageList(e.target.files)
      }
    }
  };



  const handelPdf = async (e) => {
    const { files } = e.target;
    setPageLoading(true)
    if (files.length > 0) {
      if (files[0].size > 5000000) {
        alert.error(`File should be less then 5MB`);
      } else if (
        files[0].type === "application/pdf"
      ) {
        let formData = new FormData();
        formData.append("file", files[0]);

        const res = await axios.post(`${apilink}/api/file/upload`, formData, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: token,
          },
        });

        if (res.data.success) {
          setPdfLink(res.data.result[0].Location);
          alert.success("File Successfully Uploaded");
        } else {
          alert.error(res.data.msg);
        }
      } else {
        alert.error(`File extension should be only Pdf`);
      }
    }
    setPageLoading(false)
    
  };


  
  // console.log(categoryList );
  // console.log(categoryid)
  // console.log(subCategoryid)
  return (
    <>
      <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
            <div className="container-fluid">
              <h3 className="text_Deco">Add Item</h3>
              <div className="row mt-5 p-2">
                <div className="col-12">
                  {status ? (
                    <>
                      <div class="alert alert-warning alert-dismissible">
                        <button
                          type="button"
                          class="close"
                          data-dismiss="alert"
                          onClick={() => setStatus(false)}
                        >
                          &times;
                        </button>
                        {msg}
                      </div>
                    </>
                  ) : null}
                  <form onSubmit={onAdd}>
                    <div class="form-row">
                      <div className="form-group col-lg-6 col-md-6">
                        <label for="address">Enter title:</label>
                        <input
                          type="text"
                          placeholder="Enter title"
                          className="form-control form_h"
                          name="address"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          required
                        />
                      </div>
                      <div className="form-group col-lg-6 col-md-6">
                        <label htmlFor="">Enter Quantity</label>
                        <input
                          type="number"
                          placeholder="Enter Quantity"
                          className="form-control form_h"
                          name="quantity"
                          value={quantity}
                          onChange={(e) => setQuantity(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div class="form-row">
                      <div className="form-group col-lg-6 col-md-6">
                        <label>Enter Price</label>
                        <input
                          type="number"
                          placeholder="Enter Price"
                          className="form-control form_h"
                          name="price"
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                          required
                        />
                      </div>
                      <div className="form-group col-lg-6 col-md-6">
                        <label>Enter Discount</label>
                        <input
                          type="number"
                          placeholder="Enter Discount"
                          className="form-control form_h"
                          name="discount"
                          value={discount}
                          onChange={(e) => setDiscount(e.target.value)}
                          required
                        />
                      </div>
                     
                    </div>
                    <div class="form-row">
                      <div className="form-group col-lg-6 col-md-6">
                        <label>Enter SAC Code</label>
                        <input
                          type="text"
                          placeholder="Enter SAC Code"
                          className="form-control form_h"
                          name="sacCode"
                          value={pro_sac_code}
                          onChange={(e) => setPro_sac_code(e.target.value)}
                          required
                        />
                      </div>
                      <div className="form-group col-lg-6 col-md-6">
                        <label>Enter GST Percentage</label>
                        <select
                          class="form-control form_h"
                          value={pro_gst_percentage}
                          onChange={(e) => {
                            setPro_gst_percentage(e.target.value);
                          }}
                          required
                        >
                          <option selected hidden value="">
                            --Choose--
                          </option>
                          <option value="5">5%</option>
                          <option value="12">12%</option>
                          <option value="18">18%</option>
                          <option value="28">28%</option>

                          
                        </select>
                      </div>
                     
                    </div>

                    <div class="form-row">
                      <div className="form-group col-lg-6 col-md-6">
                        <label>Enter Category</label>
                        <select
                          class="form-control form_h"
                          value={category}
                          onChange={(e) => {
                            let catlist = categoryList.filter(
                              (v) => v.cat_name == e.target.value
                            );

                            setSubCategoryList(catlist[0].sub_category );
                            setCategory(e.target.value);
                            setCategoryid(catlist[0]._id)
                          }}
                          required
                        >
                          <option selected hidden value="">
                            --Choose Category-
                          </option>
                          {categoryList?.map((val, ind) => {
                            return (
                              <>
                                <option key={ind} value={val.cat_name}>
                                  {val.cat_name}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group col-lg-6 col-md-6">
                        <label>Enter SubCategory</label>
                        <select
                          class="form-control form_h"
                          value={subCategory}
                          onChange={(e) => {

                            let catlist = subCategoryList.filter(
                              (v) => v.subcat_name == e.target.value
                            );
                            setSubCategory(e.target.value);
                            setSubCategoryid(catlist[0]._id)
                          }}
                          required
                        >
                          <option selected hidden value="">
                            --Choose SubCategory-
                          </option>
                          {subCategoryList?.map((val, ind) => {
                            return (
                              <>
                                <option key={ind} value={val.subcat_name}>
                                  {val.subcat_name}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    <div class="form-row">
                    <div className="form-group col-lg-6 col-md-6">
                        <label htmlFor="">Select Image</label>
                        <input
                          type="file"
                          className="form-control form_h"
                          name="imgfile"
                          multiple
                          accept=".png, .jpeg , .jpg"
                          required
                          onChange={handelimage}
                        />
                      </div>
                      <div className="form-group col-lg-6 col-md-6">
                      <label>Enter Place &nbsp;
                        {
                          addAll?(
                            <>
                             <small className='badge badge-danger cur' onClick={()=>{
                          setAddAll(false)
                          setPlace(placelist)
                        }}>Add All</small>
                            
                            </>
                          ):(
                            <>
                             <small className='badge badge-danger cur' onClick={()=>{
                          setAddAll(true)
                          setPlace([])
                        }}>Remove All</small>
                            
                            </>
                          )
                        } </label>
                        
                    <AllTag
                      skills={placelist}
                      tags={place}
                      setTags={setPlace}
                      plac="Enter places"
                     
                    />
                        {/* <select
                          class="form-control form_h"
                          value={place}
                          onChange={(e) => setPlace(e.target.value)}
                          required
                        >
                          <option selected hidden value="">
                            --Choose Place-
                          </option>
                          {placelist?.map((val, ind) => {
                            return (
                              <>
                                <option key={ind} value={val.name} className='text-capitalize'>
                                  {val.name}
                                </option>
                              </>
                            );
                          })}
                        </select> */}
                      </div>
                    </div>
                    <div class="form-row">
                    <div className="form-group col-lg-6 col-md-6">
                        <label htmlFor="">Upload Product Details Pdf</label>
                        <input
                          type="file"
                          className="form-control form_h"
                          name="imgfile"
                         
                          accept="application/pdf"
                          required
                          onChange={handelPdf}
                        />
                      </div>
                      
                    </div>

                    <div className="form-group">
                      <label>Enter Description:</label>
                      <JoditEditorComp text={description}
                        setText={setDescription} />
                      {/* <CKeditorCom
                        text={description}
                        setText={setDescription}
                      /> */}
                    </div>

                    <div className="text-right">
                      <button
                        type="submit"
                        className={
                          loading
                            ? 'dis btn btn-primary pl-5 pr-5'
                            : 'btn btn-primary pl-5 pr-5'
                        }
                        disabled={loading}
                      >
                        Submit
                      </button>
                    </div>
                    {loading && (
                      <div className="text-center p-2">
                        <CircularProgress color="error" size={35} />
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {pageloading && (
        <>
          <div className="pageloading">
            <div class="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AddItem;
