import React, { useContext, useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import CircularProgress from '@mui/material/CircularProgress';
import { apilink, gstPriceFunc, mainpath } from '../../data/fdata';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import parse from "html-react-parser";
import { useAlert } from 'react-alert';
import { DataContext } from '../../context/DataContext';


const Request = ({comtype}) => {
  const token = Cookies.get('_indiayour_seller_access_token');
  const {  removeSpecialCharacters , contactData } = useContext(DataContext);

  const his = useHistory();
  const alert = useAlert();

  const [filter, setFilter] = useState("All");

  const [discount,setDiscount]=  useState(5)
  const [proId,setProId]=  useState('')
  const [invoId,setInvoId]=  useState('')
  const [ordId,setOrdId]=  useState('')



  const [status,setStatus]=  useState('')
  const [activeId,setActiveId]=  useState('')
  const [loading, setLoading] = useState(false);

  const [myData, setMyData] = useState([]);
  const [myDataList, setMyDataList] = useState([]);

  const [pageNo, setPageNo] = useState(0);
  const perpage = 5;
  const pagevisit = pageNo * perpage;

  const dataall = myData.slice(pagevisit, pagevisit + perpage);
  const boxno = Math.ceil(myData.length / perpage);

  const likedChange = ({ selected }) => {
    setPageNo(selected);
  };

  const getSellerOrderRequest=async()=>{
    const res=await axios.get(`${apilink}/api/order/getSellerOrderRequest`,{
      headers: {
        Authorization: token,
      },
    })
    // console.log(res.data.result)
   if(res.data.success)
   {
    setMyData(res.data.result)
    setMyDataList(res.data.result)

   }
  }

  useEffect(()=>{
getSellerOrderRequest()
  },[])


  const updateOrder=async(e)=>{
    e.preventDefault();
    setLoading(true)

    const data={
      status,
     oid: activeId
    }
    const res=await axios.post(`${apilink}/api/order/updateOrderStatus`,data,{
      headers: {
        Authorization: token,
      },
    })
    // console.log(res.data)
if(res.data.success)
{
  // setActiveId("")
  setStatus("")
  setFilter("All")
  getSellerOrderRequest()

  

  setTimeout(() => {
   

    setLoading(false)
    alert.success("Order Request Updated Successfully")
  }, 2000);

}else{
  setTimeout(() => {
    setLoading(false)
    alert.success(res.data.msg)
  }, 2000);
}

    
  }


  const handelProduct=(e)=>{
    setFilter(e.target.value)
    if(e.target.value=="All")
    {
      
      setMyData(myDataList)
      
    }else{
      setMyData(myDataList.filter((v)=>v.orderStatus == e.target.value))
    }
    
  }

  const updateDiscountOnOrder=async(e)=>{
    e.preventDefault()
    setLoading(true)

    const data={
      discount,
      invoId,
      proId,
      ordId
    }
    // console.log(data)
    const res=await axios.post(`${apilink}/api/order/applyDiscount`,data,{
      headers: {
        Authorization: token,
      },
    })
   if(res.data.success)
   {
    setTimeout(() => {
      alert.success(res.data.msg)
      getSellerOrderRequest()
     setLoading(false)
    }, 2000);
   }else{
    setTimeout(() => {
      alert.error(res.data.msg)
      setLoading(false)
    }, 2000);
    
   }
    // console.log(data)

    
  }



  return (
    <>
      <div className="col-lg-12 col-md-12 mb-3">
        <div className="card p-3">
          <div className="dd_flex">
            <h5 className="text_Deco">Order Requests</h5>
            <div>
              <div class="form-group">
                <select class="form-control filterform" value={filter} onChange={handelProduct}>
                  <option selected hidden value="">
                    --Filter--
                  </option>
                  <option value="All">All</option>
                  <option value="Confirmed">Confirmed</option>
                    <option value="Rejected">Rejected</option>
                    <option value="Requested">Requested</option>
                    <option value="Shipped">Shipped</option>
                    <option value="Delivered">Delivered</option>

                </select>
              </div>
            </div>
          </div>
          {
                  dataall.length > 0 ?(
                    <>
          <div class="table-responsive ">
            <table class="table table-borderless">
              <thead>
                <tr>
                  <th className='w_d'>Date</th>
                  <th className="tr_w">Product Det</th>

                  <th className='w_10'>Qty</th>
                  <th className='w_10' title="This is added discount">Discount</th>
                  <th className='tr_w'>Customer</th>
                  <th className='w_20'>UserName</th>
                  <th className='w_20'>Contact</th>
                  <th className='w_20'>Status</th>
                  <th className='w_20'>Operation</th>
                </tr>
              </thead>
              <tbody>
                
                    {dataall.map((val, ind) => {
                  return (
                    <>
                      <tr className="">
                      <td className="w_d">{new Date(val.createdAt).toLocaleDateString('en-GB')}</td>
                        <td className="tr_w">
                          <div className="small_det">
                          {val?.order_product?.pro_images.length > 0 && (
                          <img
                            src={val?.order_product?.pro_images[0]}
                            alt=""
                            draggable="false"
                            
                          />
                        )}
                            

                            <div>
                              <p title={val?.order_product?.pro_title}>{val?.order_product?.pro_title}</p>
                              <h5 className="ml-2">₹{val.order_price} &nbsp; <a  href={`${mainpath}/product/${removeSpecialCharacters(val.order_product.pro_title)?.trim().toLowerCase().replace(/\s/g, "-")}/${val.order_product._id}/DEMO`} target="_blank" class="badge badge-info m-1 fn_12 ">Link</a></h5>
                            </div>
                          </div>
                         
                        </td>

                        <td className="w_10 pl-3">{val?.order_qty}</td>
                        <td className="w_10 pl-3" title="This is added discount">{val?.added_discount}%</td>

                        <td className='tr_w'>
                          <p>
                           {val?.order_customer}
                          </p>
                        </td>
                        <td className='w_20'>{val?.order_name}</td>
                        
                        <td className='w_20'><a href={`tel:${contactData.phone}`}>{contactData.phone}</a></td>

                        <td className='w_20'> 
                        
                        
                        
                        {/* { val?.orderStatus =="Pending" && <span className='badge badge-warning'>Pending</span> } { val?.orderStatus =="Rejected" && <span className='badge badge-danger'>Rejected</span> }  { val?.orderStatus =="Approved" && <span className='badge badge-success'>Approved</span> } */}
                        { val?.orderStatus =="Requested" && <span className='badge badge-warning'>Requested</span> } { val?.orderStatus =="Rejected" && <span className='badge badge-danger'>Rejected</span> }  { (val?.orderStatus =="Confirmed" || val?.orderStatus=="Shipped" || val?.orderStatus=="Delivered" ) && <span className='badge badge-success'>{val?.orderStatus}</span> }
                        </td>
                        <td className='w_20'>
                         
                          <span
                            class="badge badge-primary m-1 cur"
                            data-toggle="modal"
                            data-target={`#myyModal${val._id}`}
                          >
                            View
                          </span>
                          <div class="modal fade" id={`myyModal${val._id}`}>
                            <div class="modal-dialog modal-lg modal-dialog-centered">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h4 class="modal-title">Request Details</h4>
                                  <button
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                  >
                                    &times;
                                  </button>
                                </div>

                                <div class="modal-body">
                                  <div className="row">
                                    <div className="col-md-6 col-12 mx-auto mb-3">
                                    <div className="img_flex">
                                          {val?.order_product?.pro_images?.map((va, indd) => {
                                            return (
                                              <div className="img_flex_item" key={indd}>
                                                <img src={va} alt="" />
                                              </div>
                                            );
                                          })}
                                            </div>
                                    </div>
                                    <div className="col-md-6 col-12 mx-auto mb-3">
                                      <h3 >{val?.order_product?.pro_title}</h3>
                                      <p className="m-0 mb-3">
                                      { val?.order_product?.pro_category} &nbsp; / &nbsp; { val?.order_product?.pro_subcategory}
                                      </p>
                                     

                                      <p className="m-0">
                      <b>Product Price: </b> &nbsp;{" "}
                      {val?.order_product?.pro_price} X{" "}
                      {val?.order_qty} ={" "}
                      {val?.order_product?.pro_price * val?.order_qty}{" "}
                    </p>
                    <p className="m-0">
                      <b>Discount: </b> &nbsp;{" "}
                      {val?.order_product?.pro_discount + val?.added_discount}%
                    </p>
                    <p className="m-0">
                      <b>Discount Price: </b> &nbsp; ₹ {val?.order_price}
                    </p>
                    <p className="m-0">
                      <b>GST: </b> &nbsp; 
                      {val?.order_product?.pro_gst_percentage}%
                    </p>
                    <p className="m-0">
                      <b>Total Amount: </b> &nbsp; ₹{" "}
                     

{ (gstPriceFunc(val?.order_price,0,val?.order_product?.pro_gst_percentage))
                        }
                    </p>



                    

                  


                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-6 col-md-6 col-12 mb-3">
                                    <p class="m-0 fn_18"><b>BILL TO</b></p>
                        <p class="m-0 "><b>{val?.order_customer}</b>
                            </p>
                           
                            <p class="m-0">{val?.order_name}</p>
                            <p class="m-0">{val?.billing_address?.b_address}</p>
                            <p class="m-0">{val?.billing_address?.b_city} , {val?.billing_address?.b_district} , {val?.billing_address?.b_state}</p>
                            <p class="m-0 ">India</p>
                            {/* <p class="m-0">{val?.order_phone}</p>
                            <p class="m-0"> {val?.order_email}</p> */}
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12 mb-3">
                                    <p class="m-0 fn_18"><b>SHIP TO</b></p>
                    <p class="m-0 "><b>{val?.order_customer}</b>
                            </p>
                           
                            <p class="m-0">{val?.order_name}</p>
                            <p class="m-0">{val?.shipping_address?.s_address}</p>
                            <p class="m-0">{val?.shipping_address?.s_city} , {val?.shipping_address?.s_district} , {val?.shipping_address?.s_state}</p>
                            <p class="m-0 ">India</p>
                            {/* <p class="m-0">{val?.order_phone}</p>
                            <p class="m-0"> {val?.order_email}</p> */}

</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <span class="badge badge-dark m-1 cur" data-toggle="modal"
                            data-target={`#myyyModal${val._id}`}>Update</span>
                          <div class="modal fade" id={`myyyModal${val._id}`}>
                          <div class="modal-dialog modal modal-dialog-centered">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h4 class="modal-title">
                                  Approve Order Request
                                </h4>
                                <button
                                  type="button"
                                  class="close"
                                  data-dismiss="modal"
                                >
                                  &times;
                                </button>
                              </div>

                              <div class="modal-body">
                                <div className="row">
                                  <div className="col-lg-8 col-12 mx-auto">
                                    <form onSubmit={updateOrder}>
                                      <div class="form-group">
                                        <select class="form-control "  value={status} onChange={(e)=>{
                                          setActiveId(val._id)
                                          setStatus(e.target.value)}} required>
                                          <option selected hidden value="">
                                            --Choose--
                                          </option>

                                          <option value="Confirmed">Confirmed</option>
                    <option value="Rejected">Rejected</option>
                    <option value="Requested">Requested</option>
                                        </select>
                                      </div>
                                      <div className="text-center">
                    <button
                      type="submit"
                      className={
                        loading ? 'dis btn btn-primary' : 'btn btn-primary'
                      }
                      disabled={loading}
                    >
                     Update Status
                    </button>
                  </div>
                  {loading && (
                    <div className="text-center p-2">
                      <CircularProgress color="error" size={35} />
                    </div>
                  )}
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
{
  comtype=="BIG" && (<><span class="badge badge-info m-1 cur" data-toggle="modal"
  data-target={`#newModal${val._id}`} onClick={()=>{
    setInvoId(val.order_invoice_id)
    setOrdId(val._id)                                      
    setProId(val.order_product._id)
  }}>Discount</span>
  
  <div class="modal fade" id={`newModal${val._id}`}>
                          <div class="modal-dialog modal modal-dialog-centered">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h4 class="modal-title fn_18">
                                  Apply Discount on Order Request
                                </h4>
                                <button
                                  type="button"
                                  class="close"
                                  data-dismiss="modal"
                                  onClick={()=>{
                                    setInvoId("")
                                    setDiscount(5)
                                    setProId("")
                                    setOrdId("")
                                  }}
                                >
                                  &times;
                                </button>
                              </div>

                              <div class="modal-body">
                                <div className="row">
                                  <div className="col-lg-8 col-12 mx-auto">
                                    <form onSubmit={updateDiscountOnOrder}>
                                      <div class="form-group">
                                        <input class="form-control" type="number" value={discount} onChange={(e)=>{
                                          

                                          setDiscount(e.target.value)}} required />
                                      </div>
                                      <div className="text-center">
                    <button
                      type="submit"
                      className={
                        loading ? 'dis btn btn-primary' : 'btn btn-primary'
                      }
                      disabled={loading}
                    >
                     Update Price
                    </button>
                  </div>
                  {loading && (
                    <div className="text-center p-2">
                      <CircularProgress color="error" size={35} />
                    </div>
                  )}
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
  
  </>)


}


                        </td>
                      </tr>
                    </>
                  );
                })}
                    
                    
              </tbody>
            </table>
          </div>
          </>
                  ):(
                    <>
                    <div className="text-center p-3">
                      <h3>No Item Found</h3>
                    </div>
                    
                    </>
                  )
                }
          {
            dataall.length > 0 && <div className="row mt-3 mr-3">
            <ReactPaginate
              previousLabel={'Prev'}
              nextLabel={'Next'}
              pageCount={boxno}
              onPageChange={likedChange}
              containerClassName={'pagination'}
              // previousLinkClassName={"prevbutton"}
              // nextLinkClassName={"nextbutton"}
              // disabledClassName={"pagedisable"}
              activeClassName={'activebutton'}
            />
          </div>
          }
        </div>
      </div>
    </>
  );
};

export default Request;
